import createModel, { arbitraryKeys } from "@/__main__/data-model.mjs";

export default createModel({
  [arbitraryKeys]: {
    num_games: Number,
    num_duos: Number,
    pick_rate: Number,
    avg_placement: Number,
    top_4_percent: Number,
    top_2_percent: Number,
    top_1_percent: Number,
    tier: Number,
    augment_stage_stats: [
      {
        augment_stage: String,
        avg_placement: Number,
        num_games: Number,
        num_duos: Number,
        pick_rate: Number,
        tier: Number,
      },
    ],
    top_champions: [
      {
        champion_rank: Number,
        champion_id: Number,
        avg_placement: Number,
        num_duos: Number,
        pick_rate: Number,
        tier: Number,
      },
    ],
  },
});
